import React from 'react'
import './index.less'
import WosiBg_1 from '../../../../../public/wosiImage/wosi-bg-1.png'
import WosiBg_2 from '../../../../../public/wosiImage/wosi-bg-2.png'
import WosiBg_3 from '../../../../../public/wosiImage/wosi-bg-3.png'
import DownArrow from '../../../../../public/wosiImage/down.png'

const Wosi = () => {
  return <div className='Wosi'>
    <div className='Wosi-bg-1'>
      <img src={WosiBg_1} alt="" />
    </div>
    <div className='Wosi-bg-2'>
      <img src={WosiBg_2} alt="" />
    </div>
    <div className='Wosi-bg-3'>
      <img src={WosiBg_3} alt="" />
    </div>
    <div className='Wosi-slogan'>
      <p className='Wosi-slogan-title'>我思</p>
      <p className='Wosi-slogan-word'>发现未来生活</p>
    </div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
     
  </div>
}

export default Wosi