import React from 'react'
import './index.less'
import DownArrow from '../../../../../public/wosiImage/down.png'
import BG_JPG from '../../../../../public/wosiImage/wocheng-bg-3.png'

const WochengLife = () => {
  return <div className='WochengLife'>
    <div className='WochengLife-slogan'>
      <img className='slogan-img-1' src={BG_JPG} alt="" />
    </div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
  </div>
}

export default WochengLife