import React from 'react'
import './index.less'
// const WosiCloudBg = require('../../../public/wosiImage/life-bg.png')
import WosiCloudBg_1 from '../../../../../public/wosiImage/cloud-bg.png'
import DownArrow from '../../../../../public/wosiImage/down.png'

const WosiCloud = () => {
  return <div className='WosiCloud'>
    <div className='WosiCloud-slogan'>
      <img className='slogan-img-1' src={WosiCloudBg_1} alt="" />
      <div className='slogan-title'>
        <p>重新解构人类需求</p>
      </div>
    </div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
  </div>
}

export default WosiCloud