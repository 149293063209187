import React from 'react'
import './index.less'
// const WosiAiBg = require('../../../public/wosiImage/life-bg.png')
import WosiAiBg_1 from '../../../../../public/wosiImage/ai-bg-1.png'
import WosiAiBg_2 from '../../../../../public/wosiImage/ai-bg-2.png'
import WosiAiBg_3 from '../../../../../public/wosiImage/ai-bg-3.png'
import DownArrow from '../../../../../public/wosiImage/down.png'

const WosiAi = () => {
  return <div className='WosiAi'>
    <div className='WosiAi-slogan'>
      <img className='slogan-img-3' src={WosiAiBg_3} alt="" />
      <img className='slogan-img-1' src={WosiAiBg_1} alt="" />
      <img className='slogan-img-2' src={WosiAiBg_2} alt="" />
      <div className='slogan-title'>自建生态人工智能</div>
    </div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
  </div>
}

export default WosiAi