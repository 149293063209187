import React from 'react'
import './index.less'
// const WosiLifeBg = require('../../../public/wosiImage/life-bg.png')
import WosiLifeBg from '../../../../../public/wosiImage/life-bg.png'
import DownArrow from '../../../../../public/wosiImage/down.png'

const WosiLife = () => {
  return <div className='WosiLife'>
    <div className='WosiLife-slogan'>
      <img className='slogan-img' src={WosiLifeBg} alt="" />
      {/* <p className='slogan-word'>我感到孤独</p> */}
    </div>
    <div className='WosiLife-title'>创造未来生活方式</div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
  </div>
}

export default WosiLife