import React from 'react'
import './index.less'
import DownArrow from '../../../../../public/wosiImage/down.png'
import WoyouSlogan from '../../../../../public/wosiImage/woyou-slogan.png'
import IOSDownload from '../../../../../public/wosiImage/ios-download.png'
import AndroidDownload from '../../../../../public/wosiImage/android-download.png'

const Woyou = () => {

  enum DOWNLOAD_TYPE {
    ANDROID,
    IOS
  }

  const clockDownload = (type: DOWNLOAD_TYPE) => {
    // todo
    switch (type) {
      case DOWNLOAD_TYPE.ANDROID:
        window.open('https://wooosi.com/app/packages/wy-release-1.3.1%2810%29-2023-02-19%2023-30-05.apk', '_blank')
        break;
      case DOWNLOAD_TYPE.IOS:
        window.open("https://apps.apple.com/cn/app/%E6%88%91%E5%8F%8B/id1609635999","_blank")
        break;
    }
  }

  return <div className='Woyou'>
    <div className='Woyou-slogan'>
      {/* <p className='Woyou-slogan-title'>你好哇，</p>
      <p className='Woyou-slogan-title'>我友。</p> */}
      <img src={WoyouSlogan} alt="" />
    </div>
    <div className='Woyou-button'>
      <div className='button'>
        {/* <p className='button-title'>Android</p>
        <p className='button-desc'>立即下载</p> */}
        <div className='button-img'>
            <img src={AndroidDownload} onClick={() => {
              clockDownload(DOWNLOAD_TYPE.ANDROID)
            }} alt="" />
          </div>
        </div>
        <div className='button'>
        {/* <div className='button-mask'></div> */}
        {/* <p className='button-title'>IOS</p>
        <p className='button-desc'>立即下载</p> */}
        <div className='button-img'>
           <img src={IOSDownload} onClick={() => {
              clockDownload(DOWNLOAD_TYPE.IOS)
            }} alt="" />
        </div>
      </div>
    </div>
    <div className='Woyou-link'>
      <p><a href='http://wooosi.com/resources/web/woyou/woyouyinsizhengce.html' target='_blank'>我友隐私协议</a><a href='http://wooosi.com/resources/web/woyou/woyouyonghuxieyi.html' target='_blank'>我友用户协议</a></p>
    </div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
  </div>
}

export default Woyou