import React, { useState } from 'react'
import './index.less'
import menuLogo from '../../../public/wosiImage/menu.png'
import { useNavigate } from 'react-router-dom'

interface NavProps {
  title?: string
}

const NavBar: React.FC<NavProps> = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()

  const jumpPage = (path = '') => {
    navigate(path)
    setIsOpen(false)
  }


  return (
    <div className="nav">
      <div className="nav-logo">
        { title || '我思' }
      </div>
      {/* 大屏展示menu */}
      <div className="nav-menu">
        <img
          className="nav-menu-image"
          onClick={() => setIsOpen(true)}
          src={menuLogo}
          alt=""
        />
        {
        isOpen ? <div className="nav-list">
          <div
            className='nav-list-item'
            onClick={() => jumpPage('/home')}
          >
            首页
          </div>
          <div
            className='nav-list-item'
            onClick={() => jumpPage('/friends')}
          >
            我友
          </div>
          <div
            className='nav-list-item'
            onClick={() => jumpPage('/city')}
          >
            我城
          </div>
        </div> : null
      }
      </div>
      {/* 小屏展示menu */}
      <div className="nav-phone-menu">
        <img className="nav-phone-menu-image" onClick={() => setIsOpen
        (true)} src={menuLogo} alt="" />
      </div>
      {
        isOpen ? <div className="nav-phone-list">
          <div
            className='nav-phone-list-item'
            onClick={() => jumpPage('/home')}
          >
            首页
          </div>
          <div
            className='nav-phone-list-item'
            onClick={() => jumpPage('/friends')}
          >
            我友
          </div>
          <div
            className='nav-phone-list-item'
            onClick={() => jumpPage('/city')}
          >
            我城
          </div>
        </div> : null
      }
    </div>
  )
}

export default NavBar