import React from 'react'
import './index.less'
import DownArrow from '../../../../../public/wosiImage/down.png'
import BG_JPG from '../../../../../public/wosiImage/woyou-bg-5.png'

const WoyouDesign = () => {
  return <div className='WoyouDesign'>
    <div className='WoyouDesign-slogan'>
      <img className='slogan-img-1' src={BG_JPG} alt="" />
    </div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
  </div>
}

export default WoyouDesign