import React, { useState } from 'react';
import { useEffect } from 'react'
import '../../App.less';
import NavBar from '../../components/nav'
import Wocheng from './components/wosi';
import fullpage from '../../utils/fullpage/fullpage.js'
import WochengLife from './components/life';
import WochengAi from './components/ai';
import WochengFriends from './components/friends';
import WochengCloud from './components/cloud';
import WochengDesign from './components/design'
import WochengFramework from './components/framework'

let friendsFullpageInited = false

function FriendsPage() {
  const initFullPage = () => {
    console.log('init')
    friendsFullpageInited = true
    // @ts-ignore
    if (window && window.fullpage_api && window.fullpage_api.destroy && typeof window.fullpage_api.destroy === 'function') {
      // @ts-ignore
      window.fullpage_api.destroy('all')
    }
    setTimeout(() => {
      // @ts-ignore
      new fullpage('#friendsFullPage', {
        //options here
        autoScrolling:true,
        scrollHorizontally: true,
        navigation: true,
      })

       // @ts-ignore
       window.fullpage_api.moveTo(1)

    }, 200);
  }


  useEffect(() => {
    friendsFullpageInited = false
    const docEl = document.documentElement
      const resizeEvt = 'orientationchange' in window ? 'orientationchange': 'resize'
      const recalc = () => {
        const clientWidth = docEl.clientWidth
        if (!clientWidth) return

        if (clientWidth > 1000) {
          docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px'
        } else {
          docEl.style.fontSize = 100 * (clientWidth / 750) + 'px'
        }
      };

      recalc()

      if (!friendsFullpageInited) {
        initFullPage()
      }
      if (window.addEventListener || document.addEventListener) {
        window.addEventListener(resizeEvt, recalc, false)
        document.addEventListener('DOMContentLoaded', recalc, false)
      }

      window.addEventListener("hashchange", () => {
        //  清理之前的监听
        if (window.removeEventListener || document.removeEventListener) {
          window.removeEventListener(resizeEvt, recalc, false)
          document.removeEventListener('DOMContentLoaded', recalc, false)
        }
      });
  }, [])
  
  return (
    <div className="App">
      <header className="App-header">
        <NavBar title='我城，一生的故事' />
      </header>
      <div className='Content'>
        <div className='fullPage' id='friendsFullPage'>
          {/* 我思 */}
          <div className='section'><Wocheng /></div>
          {/* 重新解构人类需求 */}
          <div className='section'><WochengCloud /></div>
          {/* 自建生态人工智能 */}
          <div className='section'><WochengAi /></div>
          {/* 创造未来生活方式 */}
          <div className='section'><WochengLife /></div>
          {/* 我思故我在 */}
          <div className='section'><WochengFriends /></div>
          {/* 设计语言 */}
          <div className='section'><WochengDesign /></div>
          {/* 通信架构 */}
          {/* <div className='section'><WochengFramework /></div> */}
        </div>
      </div>
       
  
    </div>
  );
}

export default FriendsPage;
