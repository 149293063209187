import React from 'react'
import './index.less'
import DownArrow from '../../../../../public/wosiImage/down.png'
import WochengSlogan from '../../../../../public/wosiImage/wocheng-slogan.png'
import IOSDownload from '../../../../../public/wosiImage/ios-download.png'
import AndroidDownload from '../../../../../public/wosiImage/android-download.png'

const Wocheng = () => {

  enum DOWNLOAD_TYPE {
    ANDROID,
    IOS
  }

  const clockDownload = (type: DOWNLOAD_TYPE) => {
    // todo
    switch (type) {
      case DOWNLOAD_TYPE.ANDROID:
        window.open('http://wositest.com/app/packages/wc-debug-1.0.0%281%29-2023-03-20%2022-17-51.apk', '_blank')
        break;
    }
  }

  return <div className='Wocheng'>
    <div className='Wocheng-slogan'>
      {/* <p className='Wocheng-slogan-title'>你好哇，</p>
      <p className='Wocheng-slogan-title'>我友。</p> */}
      <img src={WochengSlogan} alt="" />
    </div>
    <div className='Wocheng-button'>
      <div className='button'>
        {/* <p className='button-title'>Android</p>
        <p className='button-desc'>立即下载</p> */}
       <div className='button-img'>
          <img src={AndroidDownload} onClick={() => {
            clockDownload(DOWNLOAD_TYPE.ANDROID)
          }} alt="" />
        </div>
      </div>
      <div className='button'>
        {/* <div className='button-mask'></div> */}
        {/* <p className='button-title'>IOS</p>
        <p className='button-desc'>立即下载</p> */}
        <div className='button-img button-mask'>
          <img src={IOSDownload} alt="" />
        </div>
      </div>
    </div>
    <div className='Wocheng-link'>
      <p><a href='http://wooosi.com/resources/web/Wocheng/Wochengyinsizhengce.html' target='_blank'>我城隐私协议</a><a href='http://wooosi.com/resources/web/Wocheng/Wochengyonghuxieyi.html' target='_blank'>我城用户协议</a></p>
    </div>
    <div className='arrow'>
      <img src={DownArrow} alt="" />
    </div>
  </div>
}

export default Wocheng