import { Routes, Route, Outlet, useLocation, useNavigate } from "react-router-dom";
import HomePage from "./pages/home";
import FriendsPage from "./pages/friends";
import CityPage from './pages/city'
import "./index.css";


export default function HomeApp() {

  const location = useLocation();
  const navigate = useNavigate();

  // 如果当前位置为根路径，自动导航到 /home 路径

  if (location.pathname === '/') {
    setTimeout(() => {
      navigate('/home')
    }, 200)
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="home" element={<HomePage />} />
        <Route path="friends" element={<FriendsPage />} />
        <Route path="city" element={<CityPage />} />
        <Route path="*" element={<>404</>} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
